import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// MITT Event Bus
// https://stackoverflow.com/a/64019074/2886997
import mitt from 'mitt'

// Localization
// https://www.codeandweb.com/babeledit/tutorials/how-to-translate-your-vue-app-with-vue-i18n
import createI18n from './i18n'

// Dynamic head/meta-tags per component
// https://github.com/vueuse/head
import { createHead } from '@vueuse/head'

// Bootstrap responsive design
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'

// Importing runs initial firebase code
import '@/services/Firebase'
import '@/services/Authentication'

// Eventbus
export const emitter = mitt()

// Setup App
const head = createHead()
const app = createApp(App).use(createI18n).use(router).use(head)

app.mount('#app')
