
import * as router from '@/router'
import { defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { enableAnalytics } from '@/services/Firebase'

export default defineComponent({
  setup() {
    const isHidden = ref(localStorage.getItem('cookies') === 'consent')
    localStorage.setItem('cookies', 'consent')
    const { t } = useI18n({})
    return { t, isHidden }
  },

  methods: {
    closeAlert() {
      localStorage.setItem('cookies', 'consent')
      enableAnalytics()
      this.isHidden = true
    },

    moreInfo() {
      localStorage.setItem('cookies', 'declined')
      this.isHidden = true
      router.default.push({ name: router.routeNames.COOKIES })
    }
  }
})
