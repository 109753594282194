import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/images/Logo.svg'
import _imports_1 from '@/assets/images/Shop.svg'


const _hoisted_1 = {
  key: 0,
  class: "header container-fluid"
}
const _hoisted_2 = {
  class: "row",
  style: {"height":"100%"}
}
const _hoisted_3 = { class: "logo-container col-10 col-md-6 offset-md-3" }
const _hoisted_4 = {
  href: "https://shop.sprueche-app.de/",
  target: "_blank",
  class: "col-2",
  id: "shop-button"
}
const _hoisted_5 = { id: "shop-button-content" }
const _hoisted_6 = { id: "shop-text" }
const _hoisted_7 = {
  key: 1,
  class: "header container-fluid"
}
const _hoisted_8 = {
  class: "row",
  style: {"height":"100%"}
}
const _hoisted_9 = { class: "logo-container col-12" }
const _hoisted_10 = { id: "footerLinkContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_cookies_alert = _resolveComponent("cookies-alert")!
  const _component_download_app_alert = _resolveComponent("download-app-alert")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.useSimpleHeader)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("img", {
                src: _imports_0,
                id: "header-logo",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goHome && _ctx.goHome(...args)))
              })
            ]),
            _createElementVNode("a", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("img", {
                  src: _imports_1,
                  id: "shop-icon",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.goShop && _ctx.goShop(...args)))
                }),
                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.t('shop')), 1)
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.useSimpleHeader)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("img", {
                src: _imports_0,
                id: "header-logo-simple",
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.goHome && _ctx.goHome(...args)))
              })
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view),
    _createElementVNode("footer", null, [
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("a", {
          class: "footerLink",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.goAGB && _ctx.goAGB(...args)))
        }, _toDisplayString(_ctx.t('agb')), 1),
        _createElementVNode("a", {
          class: "footerLink",
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.goPrivacy && _ctx.goPrivacy(...args)))
        }, _toDisplayString(_ctx.t('privacy')), 1),
        _createElementVNode("a", {
          class: "footerLink",
          onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.goImprint && _ctx.goImprint(...args)))
        }, _toDisplayString(_ctx.t('imprint')), 1),
        _createElementVNode("a", {
          class: "footerLink",
          onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.goCookies && _ctx.goCookies(...args)))
        }, _toDisplayString(_ctx.t('cookies')), 1)
      ])
    ]),
    _createVNode(_component_cookies_alert),
    _createVNode(_component_download_app_alert, {
      isHidden: !_ctx.downloadAlertShown,
      itemID: _ctx.itemID,
      "onDownload:closeAlert": _ctx.hideDownloadAlert
    }, null, 8, ["isHidden", "itemID", "onDownload:closeAlert"])
  ], 64))
}