<template>
  <!-- Advanced header (with shopify link, etc) -->
  <div class="header container-fluid" v-if="!useSimpleHeader">
    <div class="row" style="height: 100%">
      <div class="logo-container col-10 col-md-6 offset-md-3">
        <img src="@/assets/images/Logo.svg" id="header-logo" @click="goHome"/>
      </div>

      <a href="https://shop.sprueche-app.de/" target="_blank" class="col-2" id="shop-button">
        <div id="shop-button-content">
          <img src="@/assets/images/Shop.svg" id="shop-icon" @click="goShop"/>
          <span id="shop-text">{{ t('shop') }}</span>
        </div>
      </a>
    </div>
  </div>

  <!-- Simple header (only logo, centered) -->
  <div class="header container-fluid" v-if="useSimpleHeader">
    <div class="row" style="height: 100%">
      <div class="logo-container col-12">
        <img src="@/assets/images/Logo.svg" id="header-logo-simple" @click="goHome"/>
      </div>
    </div>
  </div>

  <router-view/>

  <footer>
    <div id="footerLinkContainer">
      <a class="footerLink" @click="goAGB">{{ t('agb') }}</a>
      <a class="footerLink" @click="goPrivacy">{{ t('privacy') }}</a>
      <a class="footerLink" @click="goImprint">{{ t('imprint') }}</a>
      <a class="footerLink" @click="goCookies">{{ t('cookies') }}</a>
    </div>
  </footer>

  <cookies-alert />
  <download-app-alert :isHidden="!downloadAlertShown" :itemID="itemID" @download:closeAlert="hideDownloadAlert" />
</template>

<script lang="ts">
import { useRoute } from 'vue-router'
import { defineComponent, ref } from 'vue'
import DownloadAppAlert from '@/views/components/alerts/DownloadAppAlert.vue'
import { emitter } from './main'
import { EmittEvent } from './dto/enums/EmittEvent'
import { logDownloadAppAlert, logGetAppHeaderClick } from '@/services/EventLogger'
import * as router from '@/router'
import { isIOS, isMobile } from 'mobile-device-detect'
import { useHead } from '@vueuse/head'
import { useI18n } from 'vue-i18n'
import CookiesAlert from './views/components/alerts/CookiesAlert.vue'
import { setUtmMedium } from './services/DynamicLink'

export default defineComponent({
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      scope: 'locale'
    })

    useHead({
      title: t('appname'),
      meta: [
        {
          name: 'description',
          content: t('home-header')
        },
        {
          property: 'og:title',
          content: t('appname')
        },
        {
          property: 'og:description',
          content: t('home-header')
        },
        {
          property: 'twitter:title',
          content: t('appname')
        },
        {
          property: 'twitter:description',
          content: t('home-header')
        }
      ]
    })

    const useSimpleHeader = ref(false)

    const route = useRoute()
    const itemID = route.params.id

    const downloadAlertShown = ref(false)
    return { downloadAlertShown, itemID, isIOS, isMobile, t, useSimpleHeader }
  },

  created() {
    const urlParams = new URLSearchParams(window.location.search)
    const sourceParam = urlParams.get('src')
    if (sourceParam) {
      setUtmMedium(sourceParam)
    }
  },

  mounted() {
    // Listen to global Emitt Events:
    emitter.on(EmittEvent.downloadAppAlert, this.showDownloadAlert)
  },

  components: {
    DownloadAppAlert,
    CookiesAlert
  },

  methods: {
    showDownloadAlert() {
      this.downloadAlertShown = true
      logDownloadAppAlert()
    },
    hideDownloadAlert() {
      this.downloadAlertShown = false
    },
    goHome() {
      router.default.push({ name: router.routeNames.HOME })
    },
    goPrivacy() {
      router.default.push({ name: router.routeNames.PRIVACY })
    },
    goAGB() {
      router.default.push({ name: router.routeNames.AGB })
    },
    goCookies() {
      router.default.push({ name: router.routeNames.COOKIES })
    },
    goImprint() {
      router.default.push({ name: router.routeNames.IMPRINT })
    },
    openApp() {
      logGetAppHeaderClick()
      if (this.itemID) {
        router.default.push({ name: router.routeNames.DOWNLOAD, params: { itemId: this.itemID } })
      } else {
        router.default.push({ name: router.routeNames.DOWNLOAD })
      }
    }
  },

  watch: {
    '$route.params.id': {
      handler: function(newId: string) {
        this.itemID = newId
      }
    },
    '$route.name': {
      handler: function(currentRoute: string) {
        // The ITEM Component toggles the simplified header
        this.useSimpleHeader = (currentRoute === router.routeNames.ITEM)
      }
    }
  }
})
</script>

<style lang="sass">
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap')
$breakpoint-md: 768px

html
  background-color: $primary
  // $color_background

#app
  font-family: 'Roboto', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  color: $color_on_background
  background-color: $color_background
  min-height: 100%
  min-width: 100%
  position: absolute
  padding-bottom: 75px

.header
  background-color: $primary
  height: 60px
  // box-shadow: 0px 0px 20px #000000FF

.logo-container
  height: 100%

#header-logo
  height: 100%
  cursor: pointer
  display: block
  @media (min-width: $breakpoint-md)
    margin-left: auto
    margin-right: auto

#header-logo-simple
  height: 100%
  cursor: pointer
  display: block
  margin-left: auto
  margin-right: auto

#shop-button
  // display: none
  text-decoration: none
  cursor: pointer
  height: 100%
  background-color: $color_primary_overlay
  padding: 5px
  transition: 0.2s
  &:hover
    background-color: $color_primary_overlay_light

#shop-button-content
  display: table
  height: 100%
  margin-left: auto
  margin-right: auto

#shop-icon
  height: 24px
  width: 24px
  margin: 15px 15px 15px 0px
  @media (max-width: $breakpoint-md)
    margin: 15px 0 15px 0

#shop-text
  text-decoration: none
  color: white
  font-size: 14px
  font-weight: 500
  @media (max-width: $breakpoint-md)
    display: none
  &:hover
    text-decoration: none

#footerLinkContainer
  margin-top: 24px
  margin-left: auto
  margin-right: auto
  display: table
  padding: 14px

.footerLink
  padding: 12px
  color: $color_on_background_medium
  text-decoration: none
  cursor: pointer
  &:hover
    color: $color_on_background

</style>

<i18n>
{
  "de": {
    "shop": "SHOP",
    "imprint": "Impressum",
    "privacy": "Datenschutz",
    "cookies": "Cookies",
    "agb": "AGBs"
  },

  "en": {
    "shop": "SHOP",
    "agb": "Terms of Service",
    "imprint": "Imprint",
    "privacy": "Privacy Policy",
    "cookies": "Cookies"
  }
}
</i18n>
