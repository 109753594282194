
import * as router from '@/router'
import { defineComponent } from 'vue'
import { logGetAppAlertClick } from '@/services/EventLogger'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  setup() {
    const { t } = useI18n({})

    return { t }
  },

  props: {
    isHidden: {
      type: Boolean,
      default: false
    },
    itemID: {
      type: String,
      required: false
    }
  },

  emits: [
    'download:closeAlert'
  ],

  methods: {
    closeAlert() {
      this.$emit('download:closeAlert')
    },

    openApp() {
      logGetAppAlertClick()
      this.closeAlert()
      if (this.itemID) {
        router.default.push({ name: router.routeNames.DOWNLOAD, params: { itemId: this.itemID } })
      } else {
        router.default.push({ name: router.routeNames.DOWNLOAD })
      }
    }
  }
})
