import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

export const routeNames = {
  HOME: 'Home',
  ITEM: 'Item',
  DOWNLOAD: 'Download',
  IMPRINT: 'Imprint',
  PRIVACY: 'Privacy',
  COOKIES: 'Cookies',
  AGB: 'AGB',
  IMPRINT_LEGACY: 'ImprintInfo',
  PRIVACY_LEGACY: 'PrivacyInfo',
  COOKIES_LEGACY: 'CookiesInfo',
  AGB_LEGACY: 'AGBInfo'
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: routeNames.HOME,
    // lazy-load
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/detail/:id',
    name: routeNames.ITEM,
    component: () => import('@/views/Item.vue')
  },
  {
    path: '/info/download/:itemId?',
    name: routeNames.DOWNLOAD,
    component: () => import('@/views/GetApp.vue')
  },
  {
    path: '/info/imprint',
    name: routeNames.IMPRINT,
    component: () => import('@/views/legal/Imprint.vue')
  },
  {
    path: '/imprint',
    name: routeNames.IMPRINT_LEGACY,
    component: () => import('@/views/legal/Imprint.vue')
  },
  {
    path: '/info/privacy',
    name: routeNames.PRIVACY,
    component: () => import('@/views/legal/PrivacyPolicy.vue')
  },
  {
    path: '/privacy',
    name: routeNames.PRIVACY_LEGACY,
    component: () => import('@/views/legal/PrivacyPolicy.vue')
  },
  {
    path: '/info/cookies',
    name: routeNames.COOKIES,
    component: () => import('@/views/legal/Cookies.vue')
  },
  {
    path: '/info/agb',
    name: routeNames.AGB,
    component: () => import('@/views/legal/AGB.vue')
  },
  {
    path: '/agb',
    name: routeNames.AGB_LEGACY,
    component: () => import('@/views/legal/AGB.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/NotFound.vue')
  }
]

// history: createWebHashHistory(),
const router = createRouter({
  history: createWebHistory(),
  scrollBehavior () {
    return { left: 0, top: 0 }
  },
  routes
})

export default router
