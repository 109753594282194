import firebase from 'firebase/app'
import 'firebase/analytics'

// This is usually the apiKey of firebaseConfig,
// but we currently had a verify-problem on megalol-staging firebase
// to generate dynamic short links. (we have a one-month-timeout on app.staging.mega.lol)
// So we use the live-megalol-project to generate them for now
// I've talked with Martin about this.
export const dynamicLinkAPIKey = 'AIzaSyDHBWakuZvVhUvwaUubur4UnGRK-OC-wmo'

const firebaseConfig = {
  apiKey: 'AIzaSyDHBWakuZvVhUvwaUubur4UnGRK-OC-wmo',
  authDomain: 'muttertag-3db61.firebaseapp.com',
  databaseURL: 'https://muttertag-3db61.firebaseio.com',
  projectId: 'muttertag-3db61',
  storageBucket: 'muttertag-3db61.appspot.com',
  messagingSenderId: '998086074216',
  appId: '1:998086074216:web:620903858615714487c0dd',
  measurementId: 'G-54Y82VCWZC'
}

export const firebaseApp = firebase.initializeApp(firebaseConfig)

export function enableAnalytics() {
  firebaseApp.analytics().setAnalyticsCollectionEnabled(true)
}

export function disableAnalytics() {
  firebaseApp.analytics().setAnalyticsCollectionEnabled(false)
}
