import { createI18n, LocaleMessages, VueMessageType } from 'vue-i18n'

// HOW TO USE LOCALES:
// https://www.codeandweb.com/babeledit/tutorials/how-to-translate-your-vue-app-with-vue-i18n

function loadLocaleMessages(): LocaleMessages<VueMessageType> {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages: LocaleMessages<VueMessageType> = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key).default
    }
  })
  return messages
}

console.log(navigator.language)

export default createI18n({
  locale: navigator.language,
  legacy: false,
  fallbackLocale: {
    default: ['en', 'de']
  },
  messages: loadLocaleMessages()
})
