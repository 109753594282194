
import { useRoute } from 'vue-router'
import { defineComponent, ref } from 'vue'
import DownloadAppAlert from '@/views/components/alerts/DownloadAppAlert.vue'
import { emitter } from './main'
import { EmittEvent } from './dto/enums/EmittEvent'
import { logDownloadAppAlert, logGetAppHeaderClick } from '@/services/EventLogger'
import * as router from '@/router'
import { isIOS, isMobile } from 'mobile-device-detect'
import { useHead } from '@vueuse/head'
import { useI18n } from 'vue-i18n'
import CookiesAlert from './views/components/alerts/CookiesAlert.vue'
import { setUtmMedium } from './services/DynamicLink'

export default defineComponent({
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      scope: 'locale'
    })

    useHead({
      title: t('appname'),
      meta: [
        {
          name: 'description',
          content: t('home-header')
        },
        {
          property: 'og:title',
          content: t('appname')
        },
        {
          property: 'og:description',
          content: t('home-header')
        },
        {
          property: 'twitter:title',
          content: t('appname')
        },
        {
          property: 'twitter:description',
          content: t('home-header')
        }
      ]
    })

    const useSimpleHeader = ref(false)

    const route = useRoute()
    const itemID = route.params.id

    const downloadAlertShown = ref(false)
    return { downloadAlertShown, itemID, isIOS, isMobile, t, useSimpleHeader }
  },

  created() {
    const urlParams = new URLSearchParams(window.location.search)
    const sourceParam = urlParams.get('src')
    if (sourceParam) {
      setUtmMedium(sourceParam)
    }
  },

  mounted() {
    // Listen to global Emitt Events:
    emitter.on(EmittEvent.downloadAppAlert, this.showDownloadAlert)
  },

  components: {
    DownloadAppAlert,
    CookiesAlert
  },

  methods: {
    showDownloadAlert() {
      this.downloadAlertShown = true
      logDownloadAppAlert()
    },
    hideDownloadAlert() {
      this.downloadAlertShown = false
    },
    goHome() {
      router.default.push({ name: router.routeNames.HOME })
    },
    goPrivacy() {
      router.default.push({ name: router.routeNames.PRIVACY })
    },
    goAGB() {
      router.default.push({ name: router.routeNames.AGB })
    },
    goCookies() {
      router.default.push({ name: router.routeNames.COOKIES })
    },
    goImprint() {
      router.default.push({ name: router.routeNames.IMPRINT })
    },
    openApp() {
      logGetAppHeaderClick()
      if (this.itemID) {
        router.default.push({ name: router.routeNames.DOWNLOAD, params: { itemId: this.itemID } })
      } else {
        router.default.push({ name: router.routeNames.DOWNLOAD })
      }
    }
  },

  watch: {
    '$route.params.id': {
      handler: function(newId: string) {
        this.itemID = newId
      }
    },
    '$route.name': {
      handler: function(currentRoute: string) {
        // The ITEM Component toggles the simplified header
        this.useSimpleHeader = (currentRoute === router.routeNames.ITEM)
      }
    }
  }
})
